@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@font-face {
  font-family: "High Spirited";
  font-weight: normal;
  src: url("../fonts/High-Spirited.ttf") format("truetype");
  font-style: normal;
}

@font-face {
  font-family: "Century Gothic";
  font-weight: normal;
  src: url("../fonts/Century-Gothic.ttf") format("truetype");
  font-style: normal;
}

@font-face {
  font-family: "Century Gothic Bold";
  font-weight: bold;
  src: url("../fonts/GOTHICB.TTF") format("truetype");
  font-style: normal;
}

@layer base {
  html {
    @apply block p-0 m-0 overflow-hidden box-border font-primary text-theme-black;
    height: -webkit-fill-available;
  }
  body {
    @apply block p-0 m-0 box-border;
    min-height: -webkit-fill-available;
    height: -webkit-fill-available;
  }
  #root,
  .App {
    min-height: -webkit-fill-available;
    height: -webkit-fill-available;
  }
  *,
  ::before,
  ::after {
    @apply box-border p-0 m-0;
  }
  h1 {
    @apply text-[38px] font-playfair-display font-normal mb-8 text-theme-black;
  }
  h2 {
    @apply text-sm font-century-gothic leading-5;
  }
  h3 {
    @apply text-2xl font-century-gothic-bold leading-5;
  }
  h4 {
    @apply text-xl;
  }
  h5 {
    @apply text-lg;
  }
  p {
    @apply text-base font-normal font-primary text-center;
  }
  a {
    @apply text-sm underline text-black;
  }
  button {
    @apply text-base font-primary text-center rounded-xl py-2 px-3;
  }
  button.rounded-rose {
    @apply bg-primary-rose font-century-gothic-bold text-white border-none rounded-xl;
  }
  button.light-rose {
    @apply border-2 border-rose-300 bg-white font-century-gothic-bold text-primary-rose rounded-2xl;
  }
  button.square-white {
    @apply bg-white font-century-gothic text-theme-black border border-gray-200 rounded-none shadow-[0_0_10px_2px_rgba(135,135,135,0.25)];
  }
  input.input-rose {
    @apply bg-white font-century-gothic text-theme-black border border-gray-200 rounded-xl;
  }
  input.input-white {
    @apply bg-white font-century-gothic text-theme-black border border-gray-200 rounded-none shadow-[0_0_10px_2px_rgba(135,135,135,0.25)];
  }
}

@layer components {
  .wedding-rose::after {
    @apply absolute -bottom-0.5 w-full;
    content: "";
    background-image: linear-gradient(
      to top,
      rgba(255, 245, 246, 1),
      rgba(245, 230, 231, 0.9) 15%,
      rgba(245, 230, 231, 0.88) 18%,
      rgba(255, 245, 246, 0)
    );
    height: 25%;
  }

  .birthday-banner::after {
    @apply absolute -bottom-0.5 w-full;
    content: "";
    background-image: linear-gradient(
      to top,
      rgba(255, 255, 255, 1),
      rgba(245, 245, 245, 0.9) 15%,
      rgba(245, 245, 245, 0)
    );
    height: 30%;
  }

  .App {
    @apply md:bg-gray-50;
  }

  .theme-layout {
    @apply overflow-y-auto bg-cover bg-right-bottom bg-no-repeat relative md:max-w-[600px] md:mx-auto;
    min-height: -webkit-fill-available;
    height: -webkit-fill-available;
  }

  .template-container {
    overflow-y: auto;
    min-height: -webkit-fill-available;
    height: 100%;
  }

  .card {
    @apply bg-white shadow-[0_0_10px_2px_rgba(135,135,135,0.25)];
  }

  .card.rounded {
    @apply rounded-xl;
  }

  .card.square {
    @apply rounded-none;
  }

  .highlight {
    @apply bg-rose-100 py-0.5 px-1 uppercase;
  }

  .font-shadow {
    text-shadow: 1px 2px 3px rgba(70, 70, 70, 0.25);
  }
}
